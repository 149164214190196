<template>
  <v-main style="z-index: 2 !important; height: 75px">
    <v-navigation-drawer v-model="drawer" absolute temporary color="#ffffff">
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="menu-color">
          <v-list-item-title class="mt-5 text-left">
            <v-btn
              :to="{ name: 'Dashboard', params: { id: $route.params.id } }"
              v-if="$route.params.id"
              x-large
              color="#2758f6"
              text
              @click="forceRerender('Dashboard')"
              ><v-icon class="mr-3">mdi-view-dashboard</v-icon
              >&nbsp;&nbsp;Dashboard</v-btn
            ></v-list-item-title
          >

          <v-list-item-title class="mt-5 text-left">
            <v-btn
              :to="{ name: 'Projects', params: { id: $route.params.id } }"
              v-if="$route.params.id"
              @click="forceRerender('Projects')"
              x-large
              color="#2758f6"
              text
              ><v-icon class="mr-3">mdi-projector-screen</v-icon>&nbsp;&nbsp;
              Projects</v-btn
            ></v-list-item-title
          >

          <v-list-item-title
            class="mt-5 text-left"
            v-if="
              ($route.name !== 'CompaniesListing' &&
                $route.params.id &&
                selectedCompanyUserStatus !== 'employee' &&
                checkPermission('TeamMembers')) ||
              teamLeadStatus > 0
            "
          >
            <v-btn
              :to="{ name: 'TeamMembers', params: { id: $route.params.id } }"
              @click="forceRerender('TeamMembers')"
              x-large
              text
              color="#2758f6"
              ><v-icon class="mr-3">mdi-account-multiple</v-icon>&nbsp;&nbsp;
              Team Members</v-btn
            ></v-list-item-title
          >

          <v-list-item-title class="mt-5 text-left">
            <v-btn
              :to="{ name: 'Task', params: { id: $route.params.id } }"
              v-if="$route.params.id"
              x-large
              text
              color="#2758f6"
              ><v-icon class="mr-3">mdi-clipboard-text</v-icon>&nbsp;&nbsp;
              Activities</v-btn
            ></v-list-item-title
          >

          <v-list-item-title class="mt-5 text-left">
            <v-btn
              :to="{ name: 'WorkDiary', params: { id: $route.params.id } }"
              v-if="$route.params.id"
              x-large
              text
              color="#2758f6"
              ><v-icon class="mr-3 mdi mdi-image-search-outline"></v-icon
              >&nbsp;&nbsp; Work Diary</v-btn
            ></v-list-item-title
          >

          <v-list-item-title class="mt-5 text-left">
            <v-btn
              :to="{ name: 'Reports', params: { id: $route.params.id } }"
              v-if="$route.params.id"
              x-large
              text
              color="#2758f6"
              ><v-icon class="mr-3 mdi mdi-file-chart"></v-icon>&nbsp;&nbsp;
              Reports</v-btn
            ></v-list-item-title
          >
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar
      style="background-color: #ffffff; height: 100%"
      class="pb-1 mobile_menu"
    >
      <span class="hidden-lg-and-up">
        <v-app-bar-nav-icon
          @click="drawer = true"
          class="mt-5"
          color="indigo darken-4"
        ></v-app-bar-nav-icon>
      </span>
      <!--STA Logo  -->
      <v-toolbar-title class="d-none d-sm-flex ml-md-10 mt-2 app-logo">
        <router-link to="/" tag="span" style="cursor: pointer">
          <img :src="imgSource" width="159" height="36" />
        </router-link>
      </v-toolbar-title>
      <!-- Main Links -->
      <v-toolbar-items
        class="ml-12 pt-6 active hidden-md-only hidden-sm-only hidden-xs-only"
      >
        <v-btn
          :to="{ name: 'Dashboard', params: { id: $route.params.id } }"
          v-if="$route.params.id"
          class="custom-menu"
          text
          @click="forceRerender('Dashboard')"
          ><span class="mdi mdi-view-dashboard nav-link-size"> </span
          >Dashboard</v-btn
        >
        <v-btn
          class="custom-menu"
          text
          :to="{ name: 'Projects', params: { id: $route.params.id } }"
          v-if="$route.params.id"
          @click="forceRerender('Projects')"
          ><span class="mdi mdi-projector-screen nav-link-size"> </span
          >Projects</v-btn
        >
        <v-btn
          :to="{ name: 'TeamMembers', params: { id: $route.params.id } }"
          v-if="
            ($route.name !== 'CompaniesListing' &&
              $route.params.id &&
              selectedCompanyUserStatus !== 'employee' &&
              checkPermission('TeamMembers')) ||
            teamLeadStatus > 0
          "
          @click="forceRerender('TeamMembers')"
          class="custom-menu"
          text
          ><span class="mdi mdi-account-multiple nav-link-size"> </span>Team
          Members</v-btn
        >
        <v-btn
          :to="{ name: 'Task', params: { id: $route.params.id } }"
          v-if="$route.params.id"
          class="custom-menu"
          text
          ><span class="mdi mdi-clipboard-text nav-link-size"> </span
          >Activities</v-btn
        >
        <v-btn
          :to="{ name: 'WorkDiary', params: { id: $route.params.id } }"
          v-if="$route.params.id"
          class="custom-menu"
          text
          ><span class="mdi mdi-image-search-outline nav-link-size"> </span>Work
          Diary</v-btn
        >
        <v-btn
          :to="{ name: 'Reports', params: { id: $route.params.id } }"
          v-if="$route.params.id"
          class="custom-menu"
          text
          ><span class="mdi mdi-file-chart nav-link-size"></span>Reports</v-btn
        >
      </v-toolbar-items>

      <!-- For Help Icon -->
      <div class="flex-grow-1"></div>

      <!-- For Settings Dropdown -->
      <v-toolbar-items class="pt-4 mr-5">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar>
              <img
                :src="base_url + 'storage/' + selected_company.logo"
                alt
                v-if="selected_company.logo"
                width="100"
              />
              <img
                alt
                class="circle circle-logo"
                src="../../assets/images/no-company-icon.svg"
                v-else
                width="100"
              />
            </v-avatar>
            <v-btn
              v-bind="attrs"
              v-on="on"
              text
              color="#2758f6"
              small
              class="text-left"
              ><div class="employee-name text-capitalize">
                {{ employee_name.slice(0, 10) }}
                <div
                  class="employee-company text-caption font-weight-thin text-capitalize"
                >
                  {{ $route.params.id ? company_name.slice(0, 13) + ".." : "" }}
                </div>
              </div>
              <span class="mdi mdi-chevron-down"></span>
            </v-btn>
          </template>
          <v-list class="navigation-setting">
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item
                dense
                @click="selectRoute('CompaniesListing')"
                v-if="$route.params.id"
              >
                <v-list-item-icon>
                  <v-icon>mdi-server</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Companies</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <div
                style="overflow: scroll"
                class="spacing-scroll"
                v-if="$route.params.id"
              >
                <v-list>
                  <v-list-item-group
                    color="primary"
                    v-model="selectDropDawnCompany"
                  >
                    <v-list-item
                      style="min-height: 30px; height: 30px"
                      dense
                      class="text-caption font-weight-thin"
                      :key="item.id"
                      v-for="(item, index) in companies"
                      @click="change_company(item)"
                    >
                      {{ checkSelectCompany(item, index) }}
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                        <v-divider></v-divider>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </div>

              <v-list-item dense v-if="$route.params.id" @click="accSetting()">
                <v-list-item-icon>
                  <v-icon>mdi-account-edit</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Account Settings</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                dense
                v-else
                :to="{
                  name: 'AccountSettingsRoot',
                }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-edit</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Account Settings</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                dense
                v-if="
                  keyData != null &&
                  checkKey == true &&
                  selectedCompanyUserStatus !== 'employee'
                "
                @click="forceRerender('AllTeamLeads')"
                :to="{
                  name: 'AllTeamLeads',
                  params: { id: $route.params.id },
                }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-cloud-download</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Team Leads</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                dense
                v-if="$route.params.id"
                :to="{
                  name: 'DownloadApp',
                  params: { id: $route.params.id },
                }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-cloud-download</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Download App</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                dense
                v-if="
                  $route.name !== 'CompaniesListing' &&
                  $route.params.id &&
                  selectedCompanyUserStatus !== 'employee' &&
                  !this.user.partner_id &&
                  showPermission
                "
                :to="{ name: 'Billing', params: { id: $route.params.id } }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-currency-usd</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Billing</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense @click="openNotificationModel">
                <v-list-item-icon>
                  <v-icon>mdi-notification-clear-all</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Notifications</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense @click="logOut()">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>

    <!-- modal for notification setttings -->
    <v-dialog v-model="notification_dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Notifications Settings </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col class="text-center" cols="6">
              <i
                style="color: #adb5bd"
                class="fa fa-circle"
                aria-hidden="true"
              ></i>
              <span> Disable</span>
            </v-col>
            <v-col cols="6">
              <i
                style="color: #4caf50"
                class="fa fa-circle"
                aria-hidden="true"
              ></i>
              <span> Enable</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              class="text-center"
              cols="3"
              offset-md="4"
              align-self="center"
            >
              <v-switch
                class="ml-1"
                v-model="notification_status"
                inset
                color="success"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import { baseURL } from "@/assets/js/urls";
import { mapState } from "vuex";
import router from "../../router";
import { sha256 } from "js-sha256";
export default {
  name: "navbar",
  data() {
    return {
      notification_dialog: false,
      notification_status: null,
      show: null,
      drawer: false,
      group: null,
      selectDropDawnCompany: 1,
      appTitle: "Stafftimer App",
      imgSource: require("@/assets/images/Logonew.png"),
      selectedItem: 1,
      menuItems: [
        { title: "Home", path: "/home", icon: "home" },
        { title: "Sign Up", path: "/signup", icon: "face" },
        { title: "Sign In", path: "/signin", icon: "lock_open" },
      ],
    };
  },
  watch: {
    notification_status: function (val) {
      if (this.notification_status != null) {
        this.changeNotificationStatus();
      }
    },
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("custom", [
      "team_members",
      "selected_company",
      "companies_list",
    ]),

    employee_name() {
      // It has any kind of whitespace
      if (this.user && this.user.name !== null) {
        if (/\s/.test(this.user.name)) {
          return this.user && this.user.name
            ? this.employee_class
              ? this.user.name.match(
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g
                ) !== null
                ? this.user.name
                : this.getShortName(this.user)
              : this.user.name
            : "(No name set yet)";
        } else {
          return this.user.name;
        }
      } else {
        return "(No name set yet)";
      }
    },

    Keys() {
      return this.$store.state.pf.keysData[0];
    },
    selectedCompany() {
      return this.$store.state.custom.selected_company;
    },
    employee_class() {
      return this.user && this.user.name && this.user.name.length > 17;
    },
    check_trial() {
      return this.$store.state.custom.check_trial_period_status;
    },
    companies() {
      return this.companies_list;
    },
    base_url() {
      return baseURL.API_URL;
    },
    showPermission() {
      let permission = this.$store.state.custom.selected_company.permission;
      if (this.selectedCompanyUserStatus === "secondary_user") {
        if (permission.billing) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    company_name() {
      let company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      return this.$route.name === "CompaniesListing"
        ? "All Companies"
        : this.$route.name === "AddCompany"
        ? "Add Company"
        : company
        ? company.name && company.name.length > 17
          ? this.getShortName(company)
          : company.name
        : "";
    },
    keyData() {
      if (this.$route.params.id) {
        return sha256(this.$route.params.id);
      }
      return null;
    },
    checkKey() {
      let key = false;
      if (this.$route.params.id) {
        let param_id = sha256(this.$route.params.id);
        let checkCompanyId = this.$store.state.pf.keysData.find(
          (data) => data == param_id
        );
        if (checkCompanyId) {
          key = true;
        }
      }
      return key;
    },
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
    },
    teamLeadStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.has_team;
    },
    selectedCompanyUserHasTeam() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.has_team;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
  },
  methods: {
    checkSelectCompany(item, index) {
      if (this.$route.params.id == item.id) {
        this.selectDropDawnCompany = index;
      }
    },
    selectRoute(selectRoute) {
      router.push({ name: "CompaniesListing" });
    },
    accSetting() {
      if (this.$route.params.id) {
        let company = this.companies_list.find(
          (c) => c.id === this.$route.params.id
        );
        if (
          company.company_subscription_status == true ||
          company.check_trial_period_status == true
        ) {
          router.push({
            name: "AccountSettings",
            params: { id: this.$route.params.id },
          });
        } else {
          router.push({
            name: "AccountSettingsRoot",
            params: { id: this.$route.params.id },
          });
        }
      }
    },
    openNotificationModel() {
      this.notification_status = this.$store.state.auth.user.notification_status;
      this.notification_dialog = true;
    },
    getShortName(obj) {
      return obj.name
        .match(/\b(\w)/g)
        .join(" ")
        .toUpperCase()
        .slice(0, -1)
        .concat(" " + obj.name.split(" ").slice(-1));
    },
    changeNotificationStatus() {
      let data = {
        status: this.notification_status,
      };
      this.$store
        .dispatch("custom/notificationChange", data)
        .then((response) => {
          this.$store.commit(
            "auth/change_notification_status",
            this.notification_status
          );
        })
        .catch(() => {});
    },

    checkPermission(data) {
      let permission = this.$store.state.custom.selected_company.permission;
      if (this.selectedCompanyUserStatus === "secondary_user") {
        if (data === "TeamMembers" && permission) {
          if (permission.team_member) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return true;
      }
    },

    logOut() {
      this.$store
        .dispatch("custom/user_logout")
        .then(() => {
          this.$router.push({ name: "login" });
          this.$store.commit("custom/resetState");
          this.$store.dispatch("auth/reset").then(() => {});
        })
        .catch(() => {
          this.$store.commit("custom/toggle_loader", false);
        });
    },
    supportButton: function () {
      window.open("https://support.stafftimerapp.com", "_blank").focus();
    },
    forceRerender(selectedRoute) {
      if (this.$route.name != selectedRoute) {
        if (this.$route.name === selectedRoute)
          this.$store.commit("custom/updateRouterKey");
      }
    },
    support_btn() {
      window.open("https://support.stafftimerapp.com", "_blank").focus();
    },
    change_company(company) {
      if (this.selected_company.id !== company.id) {
        const channel = new BroadcastChannel("company-broadcast-channel");
        channel.postMessage(company.id);
        let empty_projects = {
          active: [],
          archived: [],
        };
        this.$store.commit("custom/set_projects_list", empty_projects);
        this.$store.commit("custom/set_team_members_project_list", []);
        this.$store.commit("custom/set_last_working_day", null);
        this.$store.commit("custom/set_archived_team_members_list", []);
        this.$store.commit("custom/set_active_team_members_list", []);
        this.$store.commit("custom/store_socket_employee", []);
        this.$store.commit("custom/set_selected_company", company);
        this.$store.commit("custom/set_lead_team_members", []);
        this.$store.commit("custom/set_team_members_list", []);
        this.$store.dispatch("custom/getAllCompaniesList");
        this.$store.commit("custom/set_team_members", []);
        this.$store.commit("custom/set_departments", []);
        this.$store.commit("custom/set_company_department_list", []);
        this.$store.commit("custom/set_project_task_list", []);
        this.$store.commit("custom/set_work_diary_team_members", []);
        this.$store.commit("custom/set_last_working_day", "");
        setTimeout(() => this.forceRerender(this.$route.name), 500);
        this.$store.dispatch("custom/last_company_record", company.id);

        // window.location.reload()
        // this.$forceUpdate()
        if (company.user_status === "employee") {
          this.$router.push({ name: "Dashboard", params: { id: company.id } });
          // location.reload()
        } else if (this.$route.name !== "members-group-monitor") {
          this.$router.push({
            name: this.$route.name,
            params: { id: company.id },
          });
        } else if (this.$route.name == "members-group-monitor") {
          this.$router.push({
            name: "TeamMembers",
            params: { id: company.id },
          });
        }
        // console.log("reee")
        // setTimeout(() => window.location.reload(), 500);
      }
    },
  },
  mounted() {
    const channel = new BroadcastChannel("company-broadcast-channel");
    channel.addEventListener("message", (e) => {
      let company = this.companies_list.find((c) => c.id === e.data);
      if (this.selected_company !== company) {
        if (company.user_status == "employee") {
          this.$router.push({
            name: "Dashboard",
            params: { id: e.data },
          });
        } else if (this.$route.name == "members-group-monitor") {
          console.log("pushing to team members");
          this.$router.push({
            name: "TeamMembers",
            params: { id: company.id },
          });
        } else if (
          this.$route.name !== "members-group-monitor" &&
          this.$route.name !== "CompaniesListing"
        ) {
          this.$router.push({
            name: this.$route.name,
            params: { id: e.data },
          });
        } else if (this.$route.name == "CompaniesListing") {
          this.$router.push({
            name: "Dashboard",
            params: { id: e.data },
          });
        }
        setTimeout(() => {
          location.reload();
        }, 500);
      }
    });
  },
};
</script>
<style scoped>
.app-logo{
  overflow: visible !important;
}
.nav-bar {
  z-index: 1;
}
.employee-name {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
}
.employee-company {
  height: 12px;
  width: 102px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
}
.text-right {
  float: right;
}
.custom_cls {
  display: block;
  width: 100%;
}
.main-color {
  background-color: #212529;
}
.active
  a.v-btn--active.v-btn.v-btn--router.v-btn--text.theme--light.v-size--default {
  background-color: #0036e4 !important;
  color: #fff !important;
  border-radius: 3px !important;
}
.menu-color {
  background-color: #2758f6 !important;
}
a.custom-menu.v-btn.v-btn--router.v-btn--text.theme--light.v-size--default {
  color: #2758f6 !important;
  text-transform: none;
  font-family: Poppins !important;
  font-size: 14px;
}
.nav-link-size {
  font-size: small;
}
/*.nav-link-size*/
.navigation-setting {
  width: 247px;
  padding: 10px 14px;
}
.nav-link-size::before {
  font-size: 20px !important;
}
.navigation-setting .v-list-item__title {
  color: #4d75f6;
  text-align: left;
}
.navigation-setting .v-list-item__icon {
  margin-right: 15px !important;
}
.navigation-setting .v-list-item__icon i {
  background: #4d75f6;
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  width: 30px;
  height: 30px;
  text-align: center;
}
.navigation-setting .v-list-item {
  border-bottom: 1px solid #e3eef9;
  border-radius: 0px !important;
  padding: 4px 0;
}
.navigation-setting .v-list-item__title hr {
  display: none;
}
.spacing-scroll .v-list-item__title {
  padding-left: 12px;
}
.spacing-scroll {
  border-bottom: 1px solid #e3eef9;
}
a.v-btn--active.v-btn.v-btn--router.v-btn--text.theme--light.v-size--x-large {
  background: #0036e4 !important;
  color: white !important;
}
.v-navigation-drawer--open {
  height: 100vh !important;
}
</style>
